import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import About from "../components/about";
import ContactUs from "../components/contactUs";
import { ToastContainer } from "react-toastify";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Delta 8 SEO</title>
        <meta name="description" content="Delta 8 SEO" />
        <link href="https://seocannabis.agency/delta-8-seo" rel="canonical" />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <About
          titlel1="Delta 8 SEO"
          // titlel2="Are Simply  Better"
          description="Ranking high when people search for your Delta 8’s brand’s category is crucial to gaining credibility and boosting sales. Forget about paid ads that only help you lose money. We help skyrocket your Delta 8 brand to the top of the search results through organic ranking."
          desktopImg={"headlessimg"}
          mobileImg="brain.png"
          page="headless"
          titleWidth="500px"
        />
        <ContactUs
          // head1="White Glove Services"
          des1="We’ll analyze your current SEO strategy to help you secure that top spot. You can trust our team to send your Delta 8 brand to the top ranking for its search results category. Don’t miss out on sales because of your low search ranking. Give us a call and we’ll help your Delta 8 brand get more customers and business."
          // des2="We are a full-stack agency, both from a development and a creative perspective. If you want fresh designs, we’ll assign you a designer 1:1 to work on your brand’s new look.."
          // head2="Fresh Design? We Do That Too."
          image="home.png"
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Strategy;
